import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from '../firebaseConfig';

const baseUrl = "https://a.stitchso.com/d2";
//const baseUrl = "https://localhost:6001/d2";

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: async (headers, { getState }) => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const user = auth.currentUser;
    if (user) {
      await user.getIdToken(true); // force a refresh of the token
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const docApi = createApi({
  reducerPath: 'docApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['item', 'items', 'progress'],
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => {
        return {
          url: `user`
        }
      }
    }),
    getInstagram: builder.query({
      query: () => {
        return {
          url: `insta`
        }
      }
    }),
    getItems: builder.query({
      query: (arg) => {
        const { label } = arg;
        return {
          url: `items/${label}`
        }
      },
      providesTags: ['items']
    }),
    getItem: builder.query({
      query: (arg) => {
        const { label, pk, id } = arg;
        return {
          url: `item/${label}/${pk}/${id}`
        };
      },
      providesTags: ['progress']
    }),
    getSubitems: builder.query({
      query: (arg) => {
        const { label, pk } = arg;
        return {
          url: `items/${label}/${pk}/all`
        };
      },
    }),
    getConnectedAll: builder.query({
      query: (arg) => {
        const { _id, container } = arg;
        return {
          url: `connected/${container}`,
          method: 'POST',
          body: { _id }
        };
      },
      providesTags: ['item', 'items']
    }),
    getConnected: builder.query({
      query: (arg) => {
        const { _id, container, pk } = arg;
        return {
          url: `connected/${container}/${pk}`,
          method: 'POST',
          body: { _id }
        };
      },
      providesTags: ['item', 'items']
    }),
    getRelated: builder.query({
      query: (arg) => {
        const { name, value, container, pk } = arg;
        return {
          url: `related/${container}/${pk}`,
          method: 'POST',
          body: {
            name: name,
            value: value
          }
        };
      },
      providesTags: ['progress']
    }),
    getCollection: builder.query({
      query: (arg) => {
        const { label, ids } = arg;
        return {
          url: `collection/${label}`,
          method: 'POST',
          body: ids
        }
      }
    }),
    updateProperty: builder.mutation({
      query: (arg) => {
        const { label, pk, id, name, value } = arg;
        return {
          url: `item/${label}/${pk}/${id}/property`,
          method: 'POST',
          body: {
            name: name,
            value: value
          }
        }
      },
      invalidatesTags: ['progress', 'items']
    }),
    deleteProperty: builder.mutation({
      query: (arg) => {
        const { label, pk, id, name } = arg;
        return {
          url: `item/${label}/${pk}/${id}/property/delete`,
          method: 'POST',
          body: {
            name: name
          }
        }
      },
      invalidatesTags: ['progress', 'items']
    }),
    createItem: builder.mutation({
      query: (arg) => {
        const { label, data } = arg;
        return {
          url: `item/${label}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
      },
      invalidatesTags: ['items']
    }),
    deleteItem: builder.mutation({
      query: (arg) => {
        const { label, pk, id } = arg;
        return {
          url: `item/${label}/${pk}/${id}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      },
      invalidatesTags: ['items']
    })
  })
})

export const {
    useGetUserQuery,
    useGetInstagramQuery,
    useGetItemsQuery,
    useGetItemQuery,
    useGetSubitemsQuery,
    useGetConnectedAllQuery,
    useGetConnectedQuery,
    useGetRelatedQuery,
    useGetCollectionQuery,
    useUpdatePropertyMutation,
    useDeletePropertyMutation,
    useCreateItemMutation,
    useDeleteItemMutation
  } = docApi
