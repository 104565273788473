import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = "https://a.stitchso.com/stripe";
//const baseUrl = "https://localhost:6001/stripe";

export const stripeApi = createApi({
  reducerPath: 'stripeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.accessToken
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    accountCreate: builder.mutation({
      query: ({ props }) => {
        return {
          url: `account/create`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(props)
        }
      }
    }),
    accountStatus: builder.query({
      query: (arg) => {
        const { accountId } = arg;
        return {
          url: `account/status/${accountId}`
        };
      }
    }),
    accountSession: builder.mutation({
      query: (arg) => {
        const { accountId } = arg;
        return {
          url: `account/session`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(accountId)
        }
      }
    }),
    getProducts: builder.query({
      query: (arg) => {
        const { accountId } = arg;
        return {
          url: `store/products/${accountId}`
        };
      }
    }),
    getProduct: builder.query({
      query: (arg) => {
        const { accountId, productId } = arg;
        return {
          url: `store/product/${accountId}/${productId}`
        };
      }
    }),
    getOrders: builder.query({
      query: () => {
        return {
          url: `store/orders`
        };
      }
    }),
    getDashboardLink: builder.query({
      query: (arg) => {
        const { accountId } = arg;
        return {
          url: `account/dashboard/${accountId}`
        };
      }
    }),
    productCreate: builder.mutation({
      query: ({ props }) => {
        return {
          url: `store/product/create`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(props)
        }
      }
    }),
    checkout: builder.mutation({
      query: ({ data }) => {
        return {
          url: `store/checkout`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
      }
    }),
  }),
})

export const {
  useAccountStatusQuery,
  useAccountCreateMutation,
  useAccountSessionMutation,
  useGetProductsQuery,
  useGetProductQuery,
  useGetOrdersQuery,
  useGetDashboardLinkQuery,
  useProductCreateMutation,
  useCheckoutMutation
} = stripeApi
