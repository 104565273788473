import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import { selectCurrentLabel } from '../../store/itemSlice';
import { useGetNodesQuery } from '../../store/graphApi';
import { getNodeIcon } from '../../utils';

export default function NodeList({ nodePk, nodeId }) {
  const { data, error, isLoading } = useGetNodesQuery()
  const currentLabel = useSelector(selectCurrentLabel);

  let nodes = [];
  if (data) {
    nodes = [...data]
    nodes.sort((p1, p2) => { return p1.label.localeCompare(p2.label) });
  }

  if (error) {
    console.log(error)
  }

  function isCurrentNode(node) {
    return node.pk === nodePk && node.id === nodeId;
  }

  return isLoading ? <div>Loading...</div> : (
    <div style={styles.list}>
      {nodes.map((node, i) => {
        return (currentLabel === "" || currentLabel === node.label) &&
          <div key={i} style={{ ...styles.node, background: isCurrentNode(node) ? "aliceblue" : "" }}>
            <Icon icon={getNodeIcon(node.label)} iconSize="14" intent="primary" style={styles.icon} />
            <div>
              <Link to={`/node/${node.pk}/${node.id}`}>{node.name ? node.name : node.id}</Link>
              <span style={styles.nodeid}> ({node.pk}/{node.id})</span>
            </div>
          </div>
      })}
    </div>
  );
}

const styles = {
  node: {
    display: 'flex',
    direction: 'row',
    alignItems: 'center',
    borderBottom: "thin solid lightgrey",
    paddingTop: 2,
    paddingLeft: 5
  },
  list: {
    width: 150
  },
  icon: {
    paddingRight: 5,
    verticalAlign: 'middle'
  },
  nodeid: {
    fontSize: 8
  }
}
