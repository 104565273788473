import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from '../firebaseConfig';

const baseUrl = "https://a.stitchso.com/g2";
//const baseUrl = "https://localhost:6001/g2";

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: async (headers, { getState }) => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const user = auth.currentUser;
    if (user) {
      await user.getIdToken(true); // force a refresh of the token
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const graphApi = createApi({
  reducerPath: 'graphApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['node', 'nodes', 'nodex'],
  endpoints: (builder) => ({
    getNodes: builder.query({
      query: () => `nodes`,
      providesTags: ['nodes']
    }),
    getNodesByLabel: builder.query({
      query: (arg) => {
        const { label, userContentOnly } = arg;
        return {
          url: `nodes/${label}`,
          headers: {
            'X-User-Content-Only': userContentOnly === true
          },
        };
      },
      providesTags: ['nodes']
    }),
    getNode: builder.query({
      query: (arg) => {
        const { pk, id } = arg;
        return {
          url: `node/${pk}/${id}`
        };
      },
      providesTags: ['node']
    }),
    getNodeEx: builder.query({
      query: (arg) => {
        const { pk, id } = arg;
        return {
          url: `nodex/${pk}/${id}`
        };
      },
      providesTags: ['nodex']
    }),
    getConnections: builder.query({
      query: (arg) => {
        const { pk, id } = arg;
        return {
          url: `node/${pk}/${id}/connections`
        };
      },
      providesTags: ['connections']
    }),
    createNode: builder.mutation({
      query: (arg) => {
        const { data } = arg;
        return {
          url: `node`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
      },
      invalidatesTags: ['nodes']
    }),
    deleteNode: builder.mutation({
      query: (arg) => {
        const { pk, id } = arg;
        return {
          url: `node/${pk}/${id}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      },
      invalidatesTags: ['nodes']
    }),
    updateProperties: builder.mutation({
      query: (arg) => {
        const { pk, id, props } = arg;
        return {
          url: `node/${pk}/${id}/properties`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(props)      
        }
      },
      invalidatesTags: ['nodex']
    }),
    addConnection: builder.mutation({
      query: (arg) => {
        const { id1, pk1, label, direction, id2, pk2, props } = arg;
        return {
          url: `connection/${pk1}/${id1}/${label}/${direction}/${pk2}/${id2}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(props)
        }
      },
      invalidatesTags: ['nodex']
    }),
    connectNewNode: builder.mutation({
      query: (arg) => {
        // properties to include in data: { pk, id, connectionLabel, nodeLabel, nodePk, nodeId, nodeName }
        const { data } = arg;
        return {
          url: `connection/new`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)      
        }
      },
      invalidatesTags: ['nodes', 'nodex']
    }),
    deleteProperty: builder.mutation({
      query: (arg) => {
        const { pk, id, name } = arg;
        return {
          url: `node/${pk}/${id}/property/${name}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      },
      invalidatesTags: ['nodex']
    }),
    deleteConnection: builder.mutation({
      query: (arg) => {
        const { id } = arg;
        return {
          url: `connection/${id}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      },
      invalidatesTags: ['nodex']
    })
  }),
})

export const { 
  useGetNodesQuery,
  useGetNodesByLabelQuery,
  useGetNodeQuery,
  useGetNodeExQuery,
  useGetConnectionsQuery,
  useCreateNodeMutation,
  useDeleteNodeMutation,
  useAddConnectionMutation,
  useConnectNewNodeMutation,
  useUpdatePropertiesMutation,
  useDeletePropertyMutation,
  useDeleteConnectionMutation
} = graphApi
