import { Tag } from '@blueprintjs/core';

export default function Tags({ tags, selected, handleClick }) {

    return tags && <span>{tags.map((tag, i) =>
        <Tag key={i} style={styles.tag} minimal round interactive 
            intent={tag===selected ? 'primary' : 'none'}
            onClick={()=>handleClick(tag)}>{tag}</Tag>
    )}
    </span>
}

const styles = {
    tag: {
        marginRight: 2
    }
}
