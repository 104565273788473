import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Classes, Drawer } from '@blueprintjs/core';
import { store } from '../../store/store';
import { selectMotif, selectShow, showMotifAction } from '../../store/motifSlice';
import CollectionDetails from '../Collection/CollectionDetails';
import CollectionList from '../Collection/CollectionList';
import MotifDetails from '../Motif/MotifDetails';

export default function LibraryPage() {
  const isOpen = useSelector(selectShow);
  const motif = useSelector(selectMotif);
  let params = useParams();

  document.title = "Library";

  function setIsOpen(show) {
    store.dispatch(showMotifAction({ show: show, motif: null }));
  }

  return params.id && params.id !== undefined ?
    <CollectionDetails collectionId={params.id} /> :
    <div>
      <CollectionList collectionId={params.id} />
      <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)}
        canOutsideClickClose title={motif ? motif.name : ""}>
        <div className={Classes.DRAWER_BODY} style={{ padding: 10 }}>
          <MotifDetails item={motif} />
        </div>
      </Drawer>
    </div>
}
