import { useParams } from 'react-router-dom';
import { Spinner } from '@blueprintjs/core';
import { useGetUserQuery } from '../../store/docApi';
import ProjectDetails from './ProjectDetails';
import ProjectList from "./ProjectList";
import Pattern from '../Pattern/Pattern';

export default function Projects() {
  const params = useParams();
  const { data, error, isLoading } = useGetUserQuery();

  return error ? <span>Error {error.status}</span> :
    isLoading ? (<Spinner />) :
      data && params.id ? (
          params.patternId ? 
          <Pattern patternId={params.patternId} projectId={params.id} stepId={params.stepId}/> :
          <ProjectDetails username={data.name} projectId={params.id} />) :
        <ProjectList username={data.name} referrer="projects"/>
}
