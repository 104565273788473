import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetItemQuery } from '../../store/docApi';
import { Button, Icon, Spinner } from '@blueprintjs/core';
import ProjectList from '../Project/ProjectList';
import ProjectDetails from '../Project/ProjectDetails';
import ProjectCreate from '../Project/ProjectCreate';
import NotFound from '../Widgets/NotFound';
import Links from '../Widgets/Links';
import Tags from '../Widgets/Tags';

export default function UserPage() {
  let username;
  let itemname;
  let location = useLocation();
  let navigate = useNavigate();
  if (location.pathname.startsWith("/@")) {
    let path = location.pathname.substring(2);
    let parts = path.split('/');
    if (parts.length > 0) username = parts[0];
    if (parts.length > 1) itemname = parts[1];
  }
  const { data: profile, isLoading, isError } = useGetItemQuery({ label: 'profile', pk: 'profile', id: username }, { skip: !username });
  document.title = profile && profile.id ? `@${profile.id}` : "stitchso";
  const [craft, selectCraft] = useState();

  function numFormat(num) {
    return new Intl.NumberFormat().format(num);
  }

  let links = profile && profile.links ? [...profile.links] : [];
  if (profile && profile.location) {
    links.push({type: "location", name: profile.location});
  }

  return isLoading ? <Spinner /> : isError ? <NotFound /> : <div>
    {profile &&
    <div style={styles.section}>
      <div style={styles.header}>
        {profile.picture ? <img style={styles.img} src={profile.picture} alt={profile.name}
          onClick={() => navigate(`/@${profile.id}`)} /> :
          <div style={styles.noimg}><Icon icon="person" size={40} /></div>}
        <div style={styles.info}>
          {profile.id && <div style={styles.name}>@{profile.id}</div>}
          {profile.name && <div>{profile.name}</div>}
            {links && <Links links={links} />}
          <span>
            {profile.stats && profile.stats.recipes && <span>{numFormat(profile.stats.recipes)} recipes &nbsp;</span>}
            {profile.stats && profile.stats.projects && <span>{numFormat(profile.stats.projects)} projects &nbsp;</span>}
            {profile.stats && profile.stats.stitches && <span>{numFormat(profile.stats.stitches)} stitches &nbsp;</span>}
          </span>
          <Tags tags={profile.crafts} selected={craft} handleClick={(c)=>selectCraft(c === craft ? '' : c)}/>
        </div>
      </div>
      <div><Button >Follow</Button></div>
    </div>}
    <br/>
    {itemname ? (
      itemname === "create" ? <ProjectCreate username={username} /> :
        <ProjectDetails username={username} projectId={itemname} />
    ) : <ProjectList username={username} craft={craft} />}
  </div>
}

const styles = {
  header: {
    display: 'flex',
    flexDirection: 'row'
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  img: {
    borderRadius: 5,
    width: 50,
    height: 50,
    cursor: 'pointer',
    marginRight: 12
  },
  noimg: {
    marginRight: 12,
    padding: 4,
    backgroundColor: 'white',
    color: 'lightgrey',
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    height: 50,
    width: 50,
    borderRadius: 5
  },
  info: {
    display: 'flex',
    flexDirection: 'column'
  },
  name: {
    fontWeight: 'bold'
  }
}
