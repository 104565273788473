export const PRIMARY = "teal";
export const SECONDARY = "darkgrey";
export const LightBlue = "#73D2DE";
export const DarkBlue = "#227C9D";
export const White = "#ffffff";
export const LightYellow = "#FFCB77";
export const DarkYellow = "#FFBC42";
export const Pink = "#FE6D73";
export const Magenta = "#8F2D56";
export const DarkGreen = "#218380";
export const OffWhite = "#FEF9EF";
export const LightGrey = "lightgrey";
export const DarkGrey = "darkgrey";
export const ChartProgressStarted = "lightgoldenrodyellow";
export const ChartProgressDone = "#E3FAE3";
export const ChartProgressNotStarted = "white";
export const ProgressStarted = "salmon";
export const ProgressDone = "teal";
export const ProgressNotStarted = "grey";

export const Repeat = "#f4f1fa";