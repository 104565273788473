import { createSlice } from "@reduxjs/toolkit";

export const itemSlice = createSlice({
    name: 'item',
    initialState: {
        currentLabel: ""
    },
    reducers: {
        setCurrentLabel: (state, action) => {
            return { ...state, currentLabel: action.payload}
        }
    }
})

const { actions, reducer } = itemSlice;

export const { setCurrentLabel } = actions;
export const selectCurrentLabel = state => state.item.currentLabel;

export default reducer;
