export default function Notes({ notes }) {

  return notes && <div>
    {notes.map((note, i) => {
      return (typeof note === 'object') ? 
      <p key={i} style={styles.note}><b>{note.name}</b><br/>{note.note}</p> :
      <p key={i} style={styles.note}>{note}</p>
    })}
  </div>
}

const styles = {
  note: {
    padding: 10
  }
};