import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Spinner } from '@blueprintjs/core';
import { useGetItemQuery } from '../../store/docApi';
import { useGetNodeQuery } from '../../store/graphApi';
import Instructions from './Instructions';
import Overview from './Overview';
import ProductCreate from '../Store/ProductCreate';

export default function Pattern({ patternPk, patternId, projectId, stepId }) {
  const { data: item, isLoading } = useGetItemQuery({ label: "pattern", pk: patternPk, id: patternId });
  const { data: node, isLoading: isLoadingNode } = useGetNodeQuery({ label: "pattern", pk: patternPk, id: patternId });
  const [ showPublish, setShowPublish ] = useState(false);
  const [selectedTab, setSelectedTab] = useState(projectId ? "instructions" : "overview");
  const [currentLang, setCurrentLang] = useState('en');
  const navigate = useNavigate();

  function tabStyle(selected) {
    return selected ? {
      backgroundColor: '#007067',
      color: 'white'
    } : {}
  }

  function edit() {
    navigate(`/item/pattern/${item.pk}/${item.id}`)
  }

  function publish() {
    setShowPublish(true);
  }

  return isLoading || isLoadingNode ? <Spinner /> :
    item && <div>
      <div style={styles.panel}>
        <div>
          <b>{item.name}</b>
          {node && <i> ({node.name} {node.stripeProductId && node.stripeProductId})</i>}
          {item.author && <p>by <Link to={`/@${item.author}`}>@{item.author}</Link> </p>}
        </div>
        <div>
          {item.lang && item.lang.map((lang, i) =>
            <Button small key={i} style={tabStyle(lang === currentLang)}
              onClick={() => setCurrentLang(lang)}>{lang}</Button>
          )}
        </div>
      </div>
      <Button style={tabStyle(selectedTab === "overview")}
        onClick={() => setSelectedTab("overview")}>Overview</Button>
      &nbsp;&nbsp;
      <Button style={tabStyle(selectedTab === "instructions")}
        onClick={() => setSelectedTab("instructions")}>Instructions</Button>
      &nbsp;&nbsp;
      <Button minimal onClick={() => edit()}>Edit</Button>
      <Button minimal onClick={() => publish()}>Publish</Button>
      <br />
      {showPublish && <ProductCreate item={item} />}
      <br />
      {selectedTab === "overview" && <Overview patternId={patternId} patternPk={patternPk} lang={currentLang} />}
      {selectedTab === "instructions" && <Instructions patternId={patternId} patternPk={patternPk} projectId={projectId} stepId={stepId} lang={currentLang} />}
      <div align='right' className="bp4-text-small"><br /><i>{item.id}</i></div>
    </div>
}

const styles = {
  panel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  img: {
    height: 100,
    width: 100,
    borderRadius: 5
  }
};
