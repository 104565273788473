import { Colors } from '../../styles';

export default function Stitches({ palette, stitches, depth, size }) {
    function getSizeValue(value) {
        if (Array.isArray(value) && value.length > 0) {
            return value[size];
        }
        else if (typeof value == 'object') {
            return size ? value[size] : "";
        } else
            return value;
    }

    function getColor(name) {
        if (!palette || palette.shades === undefined) return "grey";
        let shade = palette.shades.find(shade => shade.name === name);
        return shade ? shade.color : "red";
    }

    function getStitchColor(stitch) {
        return stitch.color ? stitch.color : stitch.yarn ? getColor(stitch.yarn) : 'ghostwhite';
    }
    return (
        <ul>
            {stitches && stitches.map((stitch, i) => {
                return (
                    <li key={i}>
                        <span style={{ ...styles.square, backgroundColor: getStitchColor(stitch) }} >&nbsp;</span>
                        {stitch.facing && <span style={stitch.facing === 'RS' ? styles.facingRS : styles.facingWS}> {stitch.facing} </span>}
                        <span style={styles.stitch}>{stitch.stitch}</span>
                        <span>{getSizeValue(stitch.count)}</span>
                        {stitch.repeat && <span>{` in next ${getSizeValue(stitch.repeat)} sts`}</span>}
                        {stitch.note && <span style={styles.note}>{` ${stitch.note}`}</span>}
                        {stitch.stitches && <Stitches palette={palette} stitches={stitch.stitches} depth={`${depth}_${i}`} size={size}></Stitches>}
                    </li>
                )
            })}
        </ul>
    );
}

const styles = {
    stitch: {
        fontWeight: 'bold',
    },
    note: {
        fontStyle: 'italic',
    },
    square: {
        width: 16,
        height: 16,
        backgroundColor: 'red'
    },
    facingRS: {
        color: Colors.DarkBlue,
    },
    facingWS: {
        color: Colors.Magenta,
    },
};
