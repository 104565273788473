import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Checkbox } from '@blueprintjs/core';
import { useGetNodesByLabelQuery } from '../../store/graphApi';

export default function StoreList() {
  const [userContentOnly, setUserContentOnly] = useState(true);
  const { data: nodes, error, isLoading } = useGetNodesByLabelQuery({ label: "store", userContentOnly: userContentOnly })
  const navigate = useNavigate();

  return error ? <span>Error {error.status}</span> :
    isLoading ? (<div>Loading...</div>) :
      <div>
        <Checkbox checked={userContentOnly} onChange={(e) => setUserContentOnly(e.target.checked)}>My stores only</Checkbox>
        <div style={styles.list}>
          {nodes.map((node, i) => (
            <Card style={styles.card} key={i} interactive onClick={() => navigate(`/store/${node.pk}/${node.id}`)}>
              <div style={styles.cardContent}>
                <img src={node.picture} alt={node.name} style={styles.logo} />
              </div>
            </Card>
          ))}
        </div>
      </div>
}

const styles = {
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  card: {
    margin: 10,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '200px',
    height: '200px',
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain'
  }
}