import { useNavigate } from 'react-router-dom';
import { Icon, Tab, Tabs } from "@blueprintjs/core";
import { labels, getItemIcon } from "../../utils";

export default function ItemNav({itemLabel}) {
  const navigate = useNavigate();

  function onChange(label) {
    navigate(`/item/${label}`);
  }

  return (
    <Tabs onChange={onChange} selectedTabId={itemLabel}>
      {labels.map(label =>
        <Tab key={label} active={itemLabel === label} id={label}>
          <Icon icon={getItemIcon(label)} color={itemLabel === label ? "green" : "grey"} />&nbsp;
          {label}
        </Tab>
      )}
    </Tabs>
  );
}

