import { Link } from "react-router-dom";
import { auth } from '../../firebaseConfig';
import { Card } from "@blueprintjs/core";
import { store } from "../../store/store";
import { showMotifAction } from "../../store/motifSlice";
import ColorChart from "../Widgets/ColorChart";
import KnitChart from "../Widgets/KnitChart";

export default function MotifCard({ motif }) {
  function showMotifDetails() {
    store.dispatch(showMotifAction({
      show: true,
      motif: motif
    }));
  }

  return (
    <Card interactive style={styles.card} onClick={() => showMotifDetails()}>
      {motif.picture && <img style={styles.img} src={motif.picture} alt={motif.name} />}
      {motif.colorchart && motif.data && <ColorChart item={motif} thumbnail={true} />}
      {motif.knitchart && <KnitChart steps={motif.steps} item={motif} chartid={motif.id} thumbnail={true} />}
      <div>
        {auth.currentUser ?
          <Link to={`/motif/${motif.pk}/${motif.id}`}>{motif.name ? motif.name : motif.id}</Link> :
          <span>{motif.name ? motif.name : motif.id}</span>}
      </div>
    </Card>
  )
}

const styles = {
  card: {
    borderRadius: 5,
    margin: 5,
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  img: {
    maxHeight: 100,
    maxWidth: 100,
  }
}
