import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Button, HTMLTable, Icon, TextArea } from '@blueprintjs/core';
import { generateId, getNodeIcon, getDisplayValue } from '../../utils';
import {
  useAddConnectionMutation, useDeleteNodeMutation, useDeleteConnectionMutation, useDeletePropertyMutation,
  useGetNodeExQuery, useConnectNewNodeMutation, useUpdatePropertiesMutation
} from '../../store/graphApi';

export default function NodeDetails({ nodePk, nodeId }) {
  const { data: node, error, isLoading } = useGetNodeExQuery({ pk: nodePk, id: nodeId });
  const navigate = useNavigate();

  const [updateProperties] = useUpdatePropertiesMutation();
  const [deleteProperty] = useDeletePropertyMutation();
  const [deleteConnection] = useDeleteConnectionMutation();
  const [addLink] = useAddConnectionMutation();
  const [linkNewNode] = useConnectNewNodeMutation();
  const [deleteNode] = useDeleteNodeMutation();

  let settings = {
    linkLabel: "",
    newNodeLabel: "",
    newNodeName: "",
    newNodeId: generateId(),
    toLabel: "",
    toId: "",
    fromLabel: "",
    fromId: "",
    propertyName: "",
    propertyValue: "",
    isOpen: false
  };

  const [setting, setSetting] = useState(settings);

  async function handleDeleteNodeConfirm() {
    await deleteNode({ pk: node.pk, id: node.id });
    setSetting({ ...setting, isOpen: false });
    navigate("/node");
  }

  function handleDeleteNode() {
    setSetting({ ...setting, isOpen: true });
  }

  function handleDeleteNodeCancel() {
    setSetting({ ...setting, isOpen: false });
  }

  async function handleDeleteLink(id) {
    await deleteConnection({id: id});
  }

  async function handleAddToLink() {
    await addLink({ direction: "to", label: setting.toLabel, id1: node.id, pk1: node.pk, id2: setting.toId, pk2: setting.toPk, props: {} });
    setSetting({ ...setting, toLabel: "", toId: "", toPk: "" });
  }

  async function handleAddFromLink() {
    await addLink({ direction: "from", label: setting.fromLabel, id1: node.id, pk1: node.pk, id2: setting.fromId, pk2: setting.fromPk, props: {} });
    setSetting({ ...setting, fromLabel: "", fromId: "", fromPk: "" });
  }

  async function handleLinkNewNode() {
    await linkNewNode({ id: node.id, pk: node.pk, label: setting.linkLabel, nodePk: setting.newNodePk, nodeId: setting.newNodeId, nodeLabel: setting.newNodeLabel, nodeName: setting.newNodeName });
    setSetting({ ...setting, linkLabel: "", newNodeId: generateId(), newNodeLabel: "", newNodeName: "" });
  }

  function handleDeleteProperty(name) {
    deleteProperty({ pk: node.pk, id: node.id, name: name });
  }

  function handleEditProperty(name, value) {
    setSetting({ ...setting, propertyName: name, propertyValue: value });
  }

  async function handleUpdateProperty() {
    let updateParams = { pk: node.pk, id: node.id, props: {} };
    updateParams.props[setting.propertyName] = setting.propertyValue;
    await updateProperties(updateParams);
    setSetting({ ...setting, propertyName: "", propertyValue: "" });
  }

  function handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setSetting({ ...setting, [name]: value });
  }

  return error ? <p><em>Error: </em>{error.status}</p> :
    isLoading ? <p><em>Loading...</em></p> :
      node && (
        <div>
          <div>
            <Icon icon={getNodeIcon(node.label)} iconSize="12" intent="primary"
              style={{ paddingRight: 5, verticalAlign: 'middle' }} />
            <b>{node.name}</b> ({node.pk})<br />
            {node.label} | {node.id} &nbsp;
            <Button icon="duplicate" minimal={true}
              onClick={() => navigator.clipboard.writeText(node.id)} />
            <br />

            <hr />
            <HTMLTable striped={true}><tbody>
              {Object.keys(node).map((prop, i) =>
                <tr key={i}>
                  <td>{prop}</td>
                  <td>{getDisplayValue(prop, node[prop])}</td>
                  <td>
                    <Button onClick={() => handleEditProperty(prop, node[prop])} icon="edit" minimal={true} />
                  </td><td>
                    <Button onClick={() => handleDeleteProperty(prop)} icon="trash" minimal={true} />
                  </td>
                </tr>
              )}

              {node.connections && node.connections.map((connection, i) =>
                <tr key={i}>
                  <td>
                    <Icon icon={connection.c_direction === "from" ? "arrow-left" : "arrow-right"} />
                    <br/> {connection.c_label}
                  </td>
                  <td colSpan="2">
                    <Icon icon={getNodeIcon(connection.label)} iconSize="12" intent="primary"
                      style={{ paddingRight: 5, verticalAlign: 'middle' }} />
                    <Link to={`/node/${connection.pk}/${connection.id}`}>{connection.name}</Link>
                  </td>
                  <td>
                    <Button onClick={() => handleDeleteLink(connection.c_id)} icon="trash" minimal={true} />
                  </td>
                </tr>
              )}

            </tbody></HTMLTable>

            <br />
            <span style={styles.name}>Name</span>
            <TextArea name="propertyName" small={true}
              value={setting.propertyName} onChange={handleChange} />
            <span style={styles.name}>Value</span>
            <TextArea growVertically={true} small={true}
              name="propertyValue" value={setting.propertyValue} onChange={handleChange} />
            <Button onClick={handleUpdateProperty} icon="floppy-disk" minimal={true} />

            <br />
            <hr />

            <table><tbody>
              <tr key="1">
                <td><Icon icon="add" /></td>
                <td>Label</td>
                <td><input name="linkLabel" value={setting.linkLabel} onChange={handleChange} /></td>
                <td>Label</td>
                <td><input name="newNodeLabel" value={setting.newNodeLabel} onChange={handleChange} /></td>
                <td rowSpan="2"><Button onClick={handleLinkNewNode} small>Link new node</Button></td>
              </tr><tr key="2">
                <td colSpan="3"></td>
                <td>Name</td>
                <td><input name="newNodeName" value={setting.newNodeName} onChange={handleChange} /></td>
              </tr>
            </tbody></table>
            <hr />
            <table><tbody>
              <tr key="1">
                <td><Icon icon="arrow-right" /></td>
                <td>Label</td>
                <td><input name="toLabel" value={setting.toLabel} onChange={handleChange} /></td>
                <td>Pk</td>
                <td><input name="toPk" value={setting.toPk} onChange={handleChange} /></td>
                <td>Id</td>
                <td><input name="toId" value={setting.toId} onChange={handleChange} /></td>
                <td><Button onClick={handleAddToLink} small>Add Link</Button></td>
              </tr>
              <tr key="2">
                <td><Icon icon="arrow-left" /></td>
                <td>Label</td>
                <td><input name="fromLabel" value={setting.fromLabel} onChange={handleChange} /></td>
                <td>Pk</td>
                <td><input name="fromPk" value={setting.fromPk} onChange={handleChange} /></td>
                <td>Id</td>
                <td><input name="fromId" value={setting.fromId} onChange={handleChange} /></td>
                <td><Button onClick={handleAddFromLink} small>Add Link</Button></td>
              </tr>
            </tbody></table>
            <hr />
            <Button intent="danger" onClick={handleDeleteNode}>Delete</Button>
            <Alert cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="warning"
              isOpen={setting.isOpen} onCancel={handleDeleteNodeCancel} onConfirm={handleDeleteNodeConfirm}>
              <p>Delete this node?</p>
              <p><b>{node.name}</b></p>
            </Alert>
          </div>
        </div>
      )
}

const styles = {
  name: {
    padding: 10
  }
}
