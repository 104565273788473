import { Colors } from "../../styles";

export default function KnitChart({ steps, chartid, thumbnail, progress }) {
  function getStepId(i) {
    return chartid + "_" + i;
  }

  function getStepProgress(i) {
    return progress && progress.progress && progress.progress[getStepId(i)];
  }

  function getStitchColor(stitch, showsingle, i) {
    if (stitch.color) return stitch.color;
    if (showsingle || stitch.showsingle) return Colors.Repeat;
    return getStepColor(i);
  }

  function getStepColor(i) {
    let p = getStepProgress(i);
    switch (p) {
      case "done":
        return Colors.ChartProgressDone;
      case "started":
        return Colors.ChartProgressStarted;
      default:
        return Colors.ChartProgressNotStarted;
    }
  }

  function getStepStyle(step, i) {
    let style = { display: 'flex', alignSelf: 'flex-end' };
    style.flexDirection = step.facing === "RS" ? 'row-reverse' : 'row';
    let p = getStepProgress(i);
    if (p === "started") {
      style.borderColor = Colors.LightYellow;
      style.borderWidth = 1;
    }
    return style;
  }

  function DrawStitches({ stitches, showsingle, stepindex }) {
    return stitches ? stitches.map((stitch, i) => (
      stitch.count ? Array(stitch.count).fill({}).map((c, j) =>
        stitch.blank ?
          <span key={`${i}_${j}`} style={styles.chartsquareblank}>*</span> :
          <div key={`${i}_${j}`} style={{ ...styles.cell, backgroundColor: getStitchColor(stitch, showsingle, stepindex) }}>
            <img style={styles.charticon} src={`knits/${stitch.stitch}.svg`} alt={stitch.stitch} />
            {Math.floor(stitch.count / 2) === j && !thumbnail ? <span style={styles.chartsquaretext}>{stitch.count}</span> : <div></div>}
          </div>
      ) :
        stitch.repeat ? Array(stitch.showsingle ? 1 : stitch.repeat).fill({}).map((r, j) =>
          <DrawStitches stitches={stitch.stitches} key={`${i}_${j}`} showsingle={showsingle || stitch.showsingle} stepindex={stepindex} />
        ) :
          <div key={i} style={{ ...styles.cell, backgroundColor: getStitchColor(stitch, showsingle, stepindex) }}>
            <img style={styles.charticon} src={`knits/${stitch.stitch}.svg`} alt={stitch.stitch} />
          </div>
    )) : <div />
  }

  return (
    <div style={thumbnail ? styles.thumbnail : {}}>
      <div style={styles.horizontal}>
        <div style={styles.chart}>
          {steps ? steps.map((step, k) => {
            return (
              <div key={k} style={{ ...styles.chart, backgroundColor: getStepColor(k) }}>
                <div style={getStepStyle(step, k)}>
                  {<span style={styles.chartsquareblank}>{!thumbnail && k+1}</span>}
                  <DrawStitches stitches={step.stitches} stepindex={k} />
                  {step.facing === "RS" ? <div /> : <span style={styles.chartsquareblank}></span>}
                </div>
              </div>
            )
          }
          ) : <span>no steps?</span>}
        </div>
      </div>
    </div>
  )
}

const styles = {
  thumbnail: {
    zoom: 0.5
  },
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    overflowY: 'hidden'
  },
  chartContainer: {
    maxHeight: 300
  },
  chart: {
    display: 'flex',
    flexDirection: 'column-reverse',
    flexWrap: 'nowrap'
  },
  cell: {
    width: 18,
    height: 18,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.LightGrey
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-start',

  },
  styleRS: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignSelf: 'flex-end'
  },
  styleWS: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  chartsquareblank: {
    width: 18,
    height: 18,
    fontSize: 12,
    textAlign: 'center',
    color: 'blueviolet'
  },
  chartsquaretext: {
    position: 'absolute',
    color: 'red',
    fontSize: 10
  },
  charticon: {
    width: '100%',
    height: '100%'
  }
}
