import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Classes, ControlGroup, Drawer, DrawerSize, InputGroup, Spinner } from '@blueprintjs/core';
import { store } from '../../store/store';
import { selectMotif, selectShow, showMotifAction } from '../../store/motifSlice';
import { useSearchQuery } from '../../store/searchApi';
import MotifDetails from "../Motif/MotifDetails";
import ColorChart from '../Widgets/ColorChart';
import KnitChart from '../Widgets/KnitChart';

export default function SearchPage() {
  const [term, setTerm] = useState('');
  const [input, setInput] = useState(term);
  const { data, error, isLoading } = useSearchQuery({ term: term });
  const isOpen = useSelector(selectShow);
  const motif = useSelector(selectMotif);

  document.title = "Search";

  function setIsOpen(show) {
    store.dispatch(showMotifAction({ show: show, motif: null }));
  }

  function search() {
    setTerm(input);
  }

  let items = data && data.value ? data.value : [];
  if (error) {
    console.log(error)
  }

  //console.log("DATA", data);

  function showMotifDetails(item) {
    store.dispatch(showMotifAction({
      show: true,
      motif: item
    }));
  }

  function ItemCard({ item }) {
    return (
      <Card interactive style={styles.card} onClick={() => showMotifDetails(item)}>
        {item.picture && <img style={styles.img} src={item.picture} alt={item.name} />}
        {item.colorchart && item.data && <ColorChart item={item} thumbnail={true} />}
        {item.knitchart && <KnitChart steps={item.steps} item={item} chartid={item.id} thumbnail={true} />}
        <div>{item.name ? item.name : item.id}</div>
        <div style={styles.label}>{item.label}</div>
      </Card>
    )
  }

  return <div>
    <ControlGroup>
      <InputGroup value={input} onChange={(e) => setInput(e.target.value)} />
      <Button onClick={() => search()}>Search</Button>
    </ControlGroup>
    {error ? <span>Error {error.status}</span> :
      isLoading ? (<Spinner />) :
        <div>
          <div style={styles.list}>
            {items.map((item, i) => (
              <ItemCard item={item} key={i} />
            ))}
          </div>
          <Drawer size={DrawerSize.LARGE} isOpen={isOpen} onClose={() => setIsOpen(false)}
            canOutsideClickClose title={motif ? motif.name : ""}>
            <div className={Classes.DRAWER_BODY} style={{ padding: 10 }}>
              <MotifDetails item={motif} />
            </div>
          </Drawer>
        </div>}
  </div>
}


const styles = {
  card: {
    borderRadius: 5,
    margin: 5,
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  img: {
    maxHeight: 100,
    maxWidth: 100,
  },
  list: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  label: {
    fontSize: 8
  }
}
