import { Link } from 'react-router-dom';
import { useGetProductsQuery } from '../../store/stripeApi';
import Picture from '../Widgets/Picture';
import { Card } from '@blueprintjs/core';

export default function Products({ stripeAccountId }) {
  const { data: products, isLoading } = useGetProductsQuery({ accountId: stripeAccountId });

  return isLoading ? <div>Loading ... </div> : (
    <div>
      <div><b>Products</b></div>
      <br />
      <div style={styles.list}>
        {products && products.data && products.data.map((product) => (
          <Card key={product.id} style={styles.card}>
            <Link to={`/product/${stripeAccountId}/${product.id}`}>
              <b>{product.name}</b>
            </Link>
            {product.images && product.images.length > 0 && <Picture picture={product.images[0]} />}
            <div><i>{product.id}</i></div>
            <div>{product.description}</div>
            <div>${(product.priceAmount / 100).toFixed(2)} {product.currency.toUpperCase()}</div>
          </Card>
        ))}
      </div>
    </div>
  );
};

const styles = {
  list: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  card: {
    width: 400,
    margin: 20
  }
}
