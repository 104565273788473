import { useState } from 'react';
import { Button, Checkbox, HTMLSelect } from '@blueprintjs/core';
import { useUpdatePropertyMutation } from '../../store/docApi';
import Steps from './Steps';
import Links from '../Widgets/Links';
import ColorChart from '../Widgets/ColorChart';

export default function Section({ item, subitem, subitems, palette, depth, project }) {
  const [updateProperty] = useUpdatePropertyMutation()
  const [showVerbal, setShowVerbal] = useState(true);
  const [expandAll, setExpandAll] = useState(false);
  const [selectedSize, setSelectedSize] = useState(
    project && project.size ? project.size : 5);

  const onCompleteSection = async (stepid, newprogress) => {
    await updateProperty({ label: "project", pk: project.pk, id: project.id, name: `progress/${stepid}`, value: newprogress })
  }

  return (
    <div>
      <Links links={subitem.links} />
      {subitem.hook && <span>Hook {subitem.hook}</span>}
      {subitem.needles && <span>Needles {subitem.needles}</span>}
      <div style={styles.options}>
        {item.sizes && item.sizes.length &&
          <div style={styles.option}>
            Size: <HTMLSelect value={selectedSize} onChange={(e) => setSelectedSize(e.target.value)} >
              {item.sizes.map((size, i) => {
                return <option key={i} value={i}>{size[0]}</option>
              })}
            </HTMLSelect>
          </div>
        }
        <Checkbox style={styles.option} checked={showVerbal} onChange={(e) => setShowVerbal(e.target.checked)}>Show verbal</Checkbox>
        <Checkbox style={styles.option} checked={expandAll} onChange={(e) => setExpandAll(e.target.checked)}>Expand all</Checkbox>
      </div>
      <Steps steps={subitem.steps} item={item} subitems={subitems} palette={palette} project={project}
        depth={depth} size={selectedSize} expandAll={expandAll} showVerbal={showVerbal} />
      {subitem.colorchart && <ColorChart item={subitem} chartid={subitem.id} project={project} depth={depth}/>}
      <Button onClick={() => onCompleteSection(depth, "done")}>Complete</Button>
    </div>
  )
}

const styles = {
  options: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  option: {
    marginRight: 10
  }
};

