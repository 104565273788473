import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = "https://a.stitchso.com/m/";
//const baseUrl = "https://localhost:6001/m";

export const mediaApi = createApi({
  reducerPath: 'mediaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.accessToken
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['item'],
  endpoints: (builder) => ({
    uploadImage: builder.mutation({
      query: (arg) => {
        const {transform, size, file, filename, ext } = arg;
        let url = transform ? `uploadr/${size}/${filename}/${ext}` : `upload/${filename}/${ext}`;
        return {
          url: url,
          method: 'POST',
          headers: {
            'Content-Type': file.type,
            'Content-Length': `${file.size}`,
              },
          body: file
        }
      },
      invalidatesTags: ['item']
    }),
    deleteImage: builder.mutation({
      query: (arg) => {
        const { name } = arg;
        return {
          url: `delete/${name}`,
          method: 'DELETE'
        }
      }
    })
  })
})

export const {
  useUploadImageMutation,
  useDeleteImageMutation
} = mediaApi
