import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = "https://a.stitchso.com/a/";
//const baseUrl = "https://localhost:6001/a";

export const openaiApi = createApi({
  reducerPath: 'openaiApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.accessToken
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    complete: builder.mutation({
      query: (arg) => {
        const { prompt } = arg;
        return {
          url: `complete`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(prompt)
        }
      }
    })
  })
})

export const {
  useCompleteMutation
} = openaiApi
