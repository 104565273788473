import { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Button } from '@blueprintjs/core';
import { useCheckoutMutation } from '../../store/stripeApi';

export default function Checkout({ product, accountId }) {
    const stripe = useStripe();
    const [message, setMessage] = useState("");
    const [checkout] = useCheckoutMutation();

    async function handleCheckout() {
        try {
            const response = await checkout({data: {
                priceIds: [ product.priceId ],
                accountId: accountId,
                productId: product.id,
                quantity: 1,
                type: "web",
            }}).unwrap();

            const { sessionId } = response;
            if (sessionId) {
                const { error } = await stripe.redirectToCheckout({ sessionId });
                if (error) {
                    console.error('Stripe checkout error:', error);
                }
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
    
        if (query.get("success")) {
          setMessage("Order placed! Your access will be enabled shortly.");
        }
    
        if (query.get("canceled")) {
          setMessage(
            "Order canceled -- continue to shop around and checkout when you're ready."
          );
        }
      }, []);
    
      return message ? <div>{message}</div> :
        <Button onClick={handleCheckout}>Checkout</Button>
};
