import { useSelector } from 'react-redux';
import { Classes, Drawer, DrawerSize, Spinner } from '@blueprintjs/core';
import { store } from '../../store/store';
import { selectMotif, selectShow, showMotifAction } from '../../store/motifSlice';
import { useGetItemsQuery } from '../../store/docApi';
import MotifCard from "./MotifCard";
import MotifDetails from "./MotifDetails";

export default function Motifs() {
  const { data, error, isLoading } = useGetItemsQuery({ label: "motif" });
  const isOpen = useSelector(selectShow);
  const motif = useSelector(selectMotif);

  document.title = "Motifs";

  function setIsOpen(show) {
    store.dispatch(showMotifAction({ show: show, motif: null }));
  }

  let items = [];
  if (data) {
    items = [...data]
    items.sort((p1, p2) => { return p1.id.localeCompare(p2.id) });
  }

  if (error) {
    console.log(error)
  }

  return <div>
    {error ? <span>Error {error.status}</span> :
    isLoading ? (<Spinner />) :
    <div>
      <div style={styles.list}>
        {items.map((motif, i) => (
          <MotifCard motif={motif} key={i} />
        ))}
      </div>
      <Drawer size={DrawerSize.LARGE} isOpen={isOpen} onClose={() => setIsOpen(false)}
        canOutsideClickClose title={motif ? motif.name : ""}>
        <div className={Classes.DRAWER_BODY} style={{ padding: 10 }}>
          <MotifDetails item={motif} />
        </div>
      </Drawer>
    </div>}
  </div>
}

const styles = {
  list: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  }
}
