import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, ProgressBar, Spinner } from '@blueprintjs/core';
import { useGetItemQuery, useUpdatePropertyMutation } from '../../store/docApi';
import { useDeleteImageMutation } from '../../store/mediaApi';
import Links from "../Widgets/Links";
import FileUpload from '../Widgets/FileUpload';
import Instagram from '../Widgets/Instagram';

const imgUrl = "https://m.stitchso.com/img/";

export default function ProjectDetails({ projectId, username }) {
    const navigate = useNavigate();
    const { data: item, isLoading, isError, error } = useGetItemQuery({ label: "project", pk: username, id: projectId });
    const [updateProperty, { isLoading: isUpdating }] = useUpdatePropertyMutation();
    const [deleteImage] = useDeleteImageMutation();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [deleteUrl, setDeleteUrl] = useState();
    const [showAddPicture, setShowAddPicture] = useState(false);
    if (item) document.title = item.name;

    function makeIt() {
        navigate(`/project/${item.id}/${item.pattern_id}`);
    }

    function edit() {
        navigate(`/item/project/${item.pk}/${item.id}`)
    }

    function addPicture() {
        setShowAddPicture(true);
    }

    function deletePictureConfirm(url) {
        setDeleteUrl(url);
        setDeleteConfirmOpen(true);
    }

    function deletePicture() {
        setDeleteConfirmOpen(false);
        if (deleteUrl.startsWith(imgUrl)) {
            deleteImage({ name: deleteUrl.substr(imgUrl.length) })
        }
        let newpictures = item.pictures.filter((el) => { return el !== deleteUrl });
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "pictures", value: newpictures })
    }

    function setPicture(picture) {
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "picture", value: picture })
    }

    function updateSummary(summary) {
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "summary", value: summary })
    }

    function getPictureName(picture) {
        let tok = picture.split("/");
        return tok[tok.length - 1];

    }

    return isLoading ? <Spinner style={{ paddingTop: 100 }} /> :
        isError ? error && error.status === 404 && <center style={{ paddingTop: 100 }}>This project is yet to be created!</center> :
            item && <div>
                <div>
                    <div style={styles.header}>
                        {item.picture && <img style={styles.topPicture} src={item.picture} alt={item.name} />}
                        <div>
                            <b>{item.name}</b>
                            <div>({item.id})</div>
                            <div>
                                <Button onClick={() => makeIt()} disabled={!item.pattern_id} intent="success">Make it!</Button>
                                &nbsp;&nbsp;
                                <Button onClick={() => addPicture()} intent="primary">Add picture</Button>
                                &nbsp;&nbsp;
                                <Button onClick={() => edit()} intent="primary">Edit</Button>
                            </div>
                        </div>
                    </div>
                    {isUpdating && <div style={{ paddingTop: 20, paddingBottom: 20 }}><ProgressBar intent='danger' /></div>}
                    {item.pattern && item.pattern.author && <div>by {item.pattern.author}</div>}
                    <div style={styles.description}>{item.description}</div>
                    {item.pictures && <div style={styles.pictures}>
                        {item.pictures.map((picture, i) => (
                            <span key={i}>
                                <img style={styles.picture} src={picture} alt={picture} />
                                <br clear="all" />
                                <div style={styles.controls}>
                                    {getPictureName(picture)}
                                    <span>
                                        <Button icon="confirm" minimal
                                            intent={picture === item.picture ? 'primary' : 'none'}
                                            onClick={() => setPicture(picture)} />
                                        <Button icon="trash" minimal
                                            onClick={() => deletePictureConfirm(picture)} />
                                    </span>
                                </div>
                            </span>

                        ))}
                    </div>}
                    {item.pattern && item.pattern.links && <Links links={item.pattern.links} />}
                </div>
                <Button icon={item.summary ? 'eye-on' : 'eye-off'}
                    onClick={() => updateSummary(!item.summary)} /> {item.summary ? 'Summary is visible' : 'Summary is hidden'}
                {item.prompt && <div><b>Prompt: </b>{item.prompt}</div>}
                <Instagram imageUrl={item.picture} caption={item.caption}/>
                {showAddPicture && <div>
                    <br />
                    <FileUpload folder={username} item={item} />
                </div>}
                <Alert cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="warning"
                    isOpen={deleteConfirmOpen} onCancel={() => setDeleteConfirmOpen(false)}
                    onConfirm={() => deletePicture()}>
                    <p>Delete this picture?</p>
                </Alert>
            </div>
}

const styles = {
    header: {
        display: 'flex'
    },
    topPicture: {
        marginRight: 10,
        maxWidth: 80,
        borderRadius: 5
    },
    pictures: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    picture: {
        maxHeight: 200,
        paddingRight: 10
    },
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 5
    },
    description: {
        padding: 15
    }
};

