import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, InputGroup } from '@blueprintjs/core';
import { useGetUserQuery } from '../../store/docApi';
import { useCreateNodeMutation } from '../../store/graphApi';
import { getNodeProps } from '../../utils';

export default function NodeCreate() {
  const { data: userinfo } = useGetUserQuery();
  const [createNode] = useCreateNodeMutation();
  const navigate = useNavigate();

  let itemProps = getNodeProps();
  let itemPropVals = {};
  itemProps.forEach(prop => {
    itemPropVals[prop.name] = "";
  });

  const [inputData, setInputData] = useState(itemPropVals);

  useEffect(() => {
    if (userinfo) {
      setInputData(prevInputData => ({
        ...prevInputData,
        pk: userinfo.name
      }));
    }
  }, [userinfo]);

  async function handleSubmit(event) {
    event.preventDefault();
    await createNode({ data: inputData });
    handleCancel();
  }

  function handleCancel() {
    navigate("/node");
  }

  function handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setInputData({ ...inputData, [name]: value });
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <br /><br />
        {itemProps.map((prop, i) =>
          <label key={i}>{prop.displayName}
            <InputGroup name={prop.name} value={inputData[prop.name]} onChange={handleChange} />
            <br />
          </label>
        )}
        <Button type="submit" intent="primary">Submit</Button>
        &nbsp;&nbsp;&nbsp;
        <Button onClick={handleCancel}>Cancel</Button>
      </form>
    </div>
  );
}
