import { useParams } from 'react-router-dom';
import PatternList from './PatternList';
import Pattern from './Pattern';

export default function Patterns() {
  let params = useParams();
  document.title = "Patterns";

  return params.id && params.id !== undefined ?
      <Pattern patternPk={params.pk} patternId={params.id} projectId={params.pid} />:
      <PatternList />
}
