export default function Palette({ subitem }) {

  return subitem && (
      <div style={styles.section}>
        <b>Yarn</b>
        {subitem.note && <p><i>{subitem.note}</i></p>}
        {subitem.brand && <div style={{paddingBottom: 5}}>{subitem.brand}</div>}
        {subitem.shades && subitem.shades.map((shade, index) => {
          return (
            <div style={styles.shade} key={index}>
              <div style={{...styles.color, backgroundColor: shade.color}}></div>
                <b>{shade.name}</b>&nbsp;{shade.shade}
                {shade.yarn} 
                {shade.count && <span>&nbsp;x {shade.count}</span>}
            </div>
          )
        })}
      </div>
  )
}

const styles = {
  section: {
    border: '1px solid #D3D8DE',
    padding: 5
  },
  shade: {
    display: 'flex',
    flexDirection: 'row',
    padding: 2
  },
  color: {
    height: 20,
    width: 20,
    borderWidth: 1,
    borderColor: 'lightgrey',
    borderStyle: 'solid',
    marginRight: 10
  }
}

