import NavMenu from './NavMenu';

export default function Layout({ showLogo, children }) {
  return (
    <div style={styles.page}>
      <NavMenu showLogo={showLogo} />
      <div style={styles.main}>
        {children}
      </div>
      <div style={styles.footer}>~ Cloudberry Apps ~</div>
    </div>
  );
}

const styles = {
  page: {
    minWidth: 350,
    display: "flex",
    flexDirection: "column"
  },
  main: {
    backgroundColor: "#f6f7f9",
    padding: 16
  },
  footer: {
    position: 'fixed',
    bottom: 4,
    width: '100%',
    textAlign: 'center',
    fontSize: 9,
    backgroundColor: "#f6f7f9"
  }
}