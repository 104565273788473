import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { store } from '../../store/store';
import { showMotifAction } from '../../store/motifSlice';
import { useGetItemQuery } from '../../store/docApi';
import MotifDetails from './MotifDetails';

export default function MotifPage() {
    let params = useParams();
    const { data: item } = useGetItemQuery({ label: "motif", pk: params.pk, id: params.id });
    useEffect(() => {
        store.dispatch(showMotifAction({show: false, motif: null}));
    });

    return item && <MotifDetails item={item} />
}
