import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Alert, FormGroup, Icon, ButtonGroup, InputGroup, NumericInput, TextArea, ControlGroup, Navbar, Alignment, Switch, TagInput } from "@blueprintjs/core";
import { useGetItemQuery, useDeleteItemMutation, useUpdatePropertyMutation, useDeletePropertyMutation } from '../../store/docApi';
import { getItemIcon, getType, getDisplayValue } from '../../utils';
import FileUpload from '../Widgets/FileUpload';
import Picture from '../Widgets/Picture';
import Complete from '../Widgets/Complete';

export default function ItemDetails({ itemLabel, itemPk, itemId }) {
  const [propertyName, setPropertyName] = useState("")
  const [propertyValue, setPropertyValue] = useState("")
  const [propertyType, setPropertyType] = useState("")
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false)
  const navigate = useNavigate();
  const { data: item, isLoading: fetchingItem } = useGetItemQuery({ label: itemLabel, pk: itemPk, id: itemId })
  const [updateProperty, { isLoading: savingItem }] = useUpdatePropertyMutation();
  const [deleteProperty] = useDeletePropertyMutation();
  const [deleteItem] = useDeleteItemMutation();

  function viewItem() {
    navigate(`/${itemLabel}/${itemPk}/${itemId}`)
  }

  async function handleDeleteItem() {
    await deleteItem({ label: item.label, pk: item.pk, id: item.id });
    setIsDeleteConfirmOpen(false);
    navigate(`/item/${itemLabel}`)
  }

  function handleDeleteProperty(name) {
    deleteProperty({ label: item.label, pk: item.pk, id: item.id, name: name });
  }

  function handleEditProperty(name, value) {
    let type = getType(name);
    let val = type === "number" ? parseInt(value) : value;
    setPropertyName(name);
    setPropertyValue(val);
    setPropertyType(type);
  }

  function handleSaveProperty() {
    updateProperty({ label: item.label, pk: item.pk, id: item.id, name: propertyName, value: propertyValue });
    setPropertyType("string");
    setPropertyName("");
    setPropertyValue("");
  }

  function handleNumberChange(value) {
    setPropertyValue(value)
  }

  function handleBoolChange(event) {
    setPropertyValue(event.target.checked);
  }

  function handleTagsChange(values) {
    setPropertyValue(values);
  }

  function handleNameChange(event) {
    let value = event.target.value;
    let type = getType(value);
    if (type === "tags" && !Array.isArray(propertyValue)) {
      if (propertyValue.length === 0 || !Number.isNaN(propertyValue)) setPropertyValue([]);
      else setPropertyValue([propertyValue]);
    }
    setPropertyName(value);
    setPropertyType(type);
  }

  function handleTextChange(event) {
    const target = event.target;
    const value = target.value;
    let val = value.length > 0 && propertyType === "number" ? parseInt(value) : value;
    setPropertyValue(val);
  }

  return (
    <div>
      {fetchingItem ? <p><em>Loading...</em></p> : savingItem ? <p><em>Saving...</em></p> :
        item === null || item === undefined ? "" : (
          <div>
            <Navbar>
              <Navbar.Group align={Alignment.LEFT}>
                <Navbar.Heading>
                  <Icon icon={getItemIcon(item.label)} color="green" /> <b>{item.name}</b>
                </Navbar.Heading>
                <Navbar.Divider />
                {item.label}
                <Navbar.Divider />
                {item.id}
              </Navbar.Group>
              <Navbar.Group align={Alignment.RIGHT}>
                <Button icon="document" onClick={() => viewItem()} minimal="true" />
                <Button icon="trash" onClick={() => setIsDeleteConfirmOpen(true)} minimal="true" />
                <Alert cancelButtonText="Cancel" confirmButtonText="Delete"
                  icon="trash" intent="danger"
                  isOpen={isDeleteConfirmOpen} onCancel={() => setIsDeleteConfirmOpen(false)}
                  onConfirm={handleDeleteItem}
                ><p>Delete this {item.label}?<br /><b>{item.name}</b></p></Alert>
              </Navbar.Group>
            </Navbar>

            <br />

            <form>
              <table className="bp3-html-table bp3-html-table-striped">
                <tbody>
                  {Object.keys(item).map(prop => !prop.startsWith('_') &&
                    <tr key={prop}>
                      <td><b>{prop}</b></td>
                      <td style={{ "maxWidth": "300px", "wordWrap": "break-word" }}>
                        {getDisplayValue(prop, item[prop])}
                      </td>
                      <td>
                        <ButtonGroup minimal="true">
                          <Button icon="edit" intent="primary" onClick={() => handleEditProperty(prop, item[prop])} />
                          <Button icon="duplicate" minimal="true" onClick={() => navigator.clipboard.writeText(item[prop])}></Button>
                          <Button icon="trash" onClick={() => handleDeleteProperty(prop)} />
                        </ButtonGroup>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <FormGroup label="Name" >
                <InputGroup name="propertyName" value={propertyName} onChange={handleNameChange} />
              </FormGroup>
              <FormGroup label="Value">
                <ControlGroup>
                  {propertyType === "number" ?
                    <NumericInput name="propertyValue" value={propertyValue} onValueChange={handleNumberChange} />
                    : propertyType === "bool" ?
                      <Switch name="propertyValue" checked={propertyValue} onChange={handleBoolChange} />
                      : propertyType === "tags" ?
                        <TagInput name={propertyValue} values={propertyValue} onChange={handleTagsChange} />
                        :
                        <TextArea name="propertyValue" value={propertyValue} onChange={handleTextChange}
                          growVertically="true" fill="true" />
                  }
                </ControlGroup>
                <br />
                <Button icon="floppy-disk" onClick={handleSaveProperty} intent="primary">Save</Button>
              </FormGroup>
            </form>
            <Complete />
            <div style={{ width: 600, overflowX: 'auto' }}>
              <Picture picture={item.picture} pictures={item.pictures} />
            </div>
            <FileUpload folder={item.pk} item={item} />
          </div>
        )}
    </div>
  );
}
