import { useGetSubitemsQuery } from '../../store/docApi';
import CollectionDetails from "./CollectionDetails";

export default function CollectionList() {
  const { data, error, isLoading } = useGetSubitemsQuery({ label: "collection", pk: "usr" })

  let items = [];
  if (data) {
    items = [...data]
    items.sort((p1, p2) => { return p2._ts - p1._ts });
  }

  if (error) {
    console.log(error)
  }

  return error ? <span>Error {error.status}</span> :
    isLoading ? (<div>Loading...</div>) :
      <div>
        {items.map((collection, i) => (
          <div key={i}>
            <div>{collection.name}</div>
            <CollectionDetails collection={collection} />     
          </div>
        ))}
      </div>
}
