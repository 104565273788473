import { Colors } from '../../styles';

export default function Glossary({ subitem }) {
  return (
      <div>
        {subitem.terms && subitem.terms.map((term, index) => {
          return (
            <div style={styles.term} key={index}>
              <b>{term.term}</b> {term.definition}
            </div>
          )
        })}
      </div>
  )
}

const styles = {
  term: {
    flexDirection: 'column',
    padding: 10,
    borderBottomWidth: 1,
    borderColor: Colors.LightGrey
  }
};

