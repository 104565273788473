import { useGetCollectionQuery } from '../../store/docApi';
import MotifCard from '../Motif/MotifCard';

export default function CollectionDetails({ collection }) {
  const { data, error, isLoading } = useGetCollectionQuery({ label: collection.type, ids: collection.ids })
  return error ? <span>Error {error.status}</span> :
    isLoading ? (<div>Loading...</div>) :
      <div style={styles.list}>
        {data && data.map((item, i) => (
          <MotifCard motif={item} key={i} />
        ))}
      </div>
}

const styles = {
  list: {
    display: "flex",
    flexDirection: "row",
    overflowX: 'auto',
    overflowY: 'hidden',
    flexWrap: 'nowrap',
    margin: 0,
    minWidth: '100%'
  }
}
