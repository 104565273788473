export default function Craft({ craft }) {

    function style() {
        let color = 'grey';
        if (craft === 'knitting') color = '#D69FD6';
        if (craft === 'crochet') color = '#BDADFF';
        return {
            fontSize: 8,
            color: 'white',
            backgroundColor: color,
            borderRadius:  craft === 'knitting' ? 90 : 5,
            paddingRight: 5,
            paddingLeft: 5,
            marginRight: 5,
            paddingTop: 2,
            paddingBottom: 2
        }
    }
    return craft &&
        <span style={style()}>
            {craft === 'knitting' && 'K'}
            {craft === 'crochet' && 'C'}
            {craft === 'origami' && 'O'}
            {craft === 'cooking' && "C"}
            {craft === 'music' && "M"}
        </span>
}

