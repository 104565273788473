import Picture from "../Widgets/Picture";
import Steps from "../Parts/Steps";
import KnitChart from "../Widgets/KnitChart";
import ColorChart from "../Widgets/ColorChart";
import Links from "../Widgets/Links";

export default function MotifDetails({ item }) {
    return item &&
        <div>
            {item.name && <b>{item.name} </b>}
            {item.author && <div>Designed by {item.author} </div>}
            ({item.id})
            {item.cols > 1 && <div>Multiple of {item.cols} sts {item.extracols && <span>plus {item.extracols}</span>}</div>}
            <Picture picture={item.picture} pictures={item.pictures} />
            {item.description}
            {item.colorchart && <ColorChart item={item} chartid={item.id} />}
            {item.knitchart && item.steps && <div><br/><KnitChart steps={item.steps} item={item} chartid={item.id} /></div>}
            {item.steps && <Steps steps={item.steps} showVerbal={true} expandAll={true} />}
            <Links links={item.links} />
            {item.sources && item.sources.map((source, i) => {
                return <div key={i}>{source.id} {source.chapter} {source.page}</div>
            })}
        </div>
}
