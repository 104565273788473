import { useParams } from 'react-router-dom';
import ItemList from './ItemList';
import ItemDetails from './ItemDetails';
import ItemNav from './ItemNav';
import ItemCreate from './ItemCreate';

export default function Items() {
  let params = useParams();
  let selectedLabel = params.label ? params.label : 'pattern';
  console.log(params)
  return (
    <div>
      <br />
      <ItemNav itemLabel={selectedLabel}/>
      <br />
      <table><tbody>
        <tr>
          <td valign="top">
            <ItemList itemLabel={selectedLabel} itemPk={params.pk} itemId={params.id} />
          </td>
          <td valign="top">
            {params.pk === 'create' ? 
              <ItemCreate itemLabel={selectedLabel} /> :
              params.label && params.pk && params.id &&
                <ItemDetails itemLabel={selectedLabel} itemPk={params.pk} itemId={params.id} />}
          </td>
        </tr></tbody>
      </table>
    </div>
  );
}
