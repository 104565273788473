import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        accessToken: ""
    },
    reducers: {
        loginAction: (state, action) => {
            return {
                ...state,
                accessToken: action.payload.accessToken
            };
        }
    }
})

const { actions, reducer } = userSlice;
export const { loginAction } = actions;

export const selectAccessToken = state => state.user.accessToken;

export default reducer;
