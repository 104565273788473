import { useState, useEffect } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import { useAccountSessionMutation } from "../store/stripeApi";

const STRIPE_KEY = 'pk_test_51LepXEA8YTXq9RcdWUbCfp7LrpbqJQkiiGlG6e0cAps8L9WIZQsrwC0DsYIMBubd7paePqf0U3esvYEEhHZPW9uI00NpXV1C8m';

export const useStripeConnect = (connectedAccountId) => {
  const [stripeConnectInstance, setStripeConnectInstance] = useState();
  const [accountSession] = useAccountSessionMutation();

  useEffect(() => {
    if (connectedAccountId) {
      const fetchClientSecret = async () => {
        try {
          const response = await accountSession({ accountId: connectedAccountId }).unwrap();
          return response.client_secret;
        } catch (error) {
          console.log("Failed to fetch ", error)
          throw ("An error occurred: ", error);
        }
      }

      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey: STRIPE_KEY,
          fetchClientSecret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#008080" // teal color, stripe's default is "#635BFF"
            },
          },
        })
      );
    }
  }, [connectedAccountId, accountSession]);

  return stripeConnectInstance;
};

export default useStripeConnect;