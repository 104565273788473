import { Button, Overlay } from "@blueprintjs/core";
import { useState } from "react";

export default function Picture({ picture, pictures }) {
    const [currentPicture, setCurrentPicture] = useState();
    const [isOpen, setIsOpen] = useState();
    const options = {
        usePortal: false,
        hasBackdrop: false,
        canOutsideClickClose: true,
        canEscapeKeyClose: true
    };

    function showOverlay(url) {
        setIsOpen(true);
        setCurrentPicture(url);
    }

    const handleClose = () => {
        setIsOpen(false);
    };

    return pictures ?
        <div style={styles.carousel}>
            <Overlay isOpen={isOpen} onClose={handleClose} {...options}>
                <img style={styles.bigPicture} src={currentPicture} alt={currentPicture}/>
                <Button icon="cross" onClick={()=>setIsOpen(false)}></Button>
            </Overlay>
            {pictures.map((picture, i) => (
                <img key={i} style={styles.picture} src={picture} alt={picture} onClick={()=>showOverlay(picture)}/>
            ))}
        </div> : picture ? (
            <div style={{ alignItems: 'center' }}>
                <img style={styles.picture} src={picture} alt={picture} />
            </div>
        ) : (
            <div></div>
        );
}

const styles = {
    carousel: {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        overflowY: 'hidden'
    },
    picture: {
        maxHeight: 200,
        maxWidth: 200,
        margin: 5,
        borderRadius: 5,
        objectFit: 'cover'
    },
    bigPicture: {
        borderRadius: 5
    }
};
