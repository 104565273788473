import { createSlice } from "@reduxjs/toolkit";

export const motifSlice = createSlice({
    name: 'motif',
    initialState: {
        motif: null,
        show: false
    },
    reducers: {
        showMotifAction: (state, action) => {
            return {
                ...state,
                show: action.payload.show,
                motif: action.payload.motif
            };
        }
    }
})

const { actions, reducer } = motifSlice;

export const { showMotifAction } = actions;
export const selectMotif = state => state.motif.motif;
export const selectShow = state => state.motif.show;

export default reducer;
