import { useParams } from "react-router";
import { auth } from "../../firebaseConfig";

export default function ProfilePage() {
  const user = auth.currentUser;
  document.title = "Profile";
  let params = useParams();
  return (
    user && (
      <div>
        {params.id}
        <img src={user.picture} alt={user.name} />
        <h2>{user.name}</h2>
        <p>{user.email}</p>
        <p>{user.given_name} {user.family_name}</p>
        <pre>{JSON.stringify(user, null, 2)}</pre>
      </div>
    )
  );
};
