import { useNavigate } from "react-router-dom";
import { Card, Icon, Spinner, Tag } from "@blueprintjs/core";
import { useGetSubitemsQuery } from '../../store/docApi';
import { Colors } from '../../styles';
import { getCraftIcon } from "../../utils";

export default function ProjectList({ username, craft, referrer }) {
  const { data, error, isLoading } = useGetSubitemsQuery({ label: 'project', pk: username }, { skip: !username })
  const navigate = useNavigate();

  let items = [];
  if (data) {
    items = [...data]
    items.sort((p1, p2) => { return p2._ts - p1._ts });
  }

  if (error) {
    console.log(error)
  }

  function show(p) {
    if (!craft) return true; // no filter set
    return p.craft && p.craft === craft;
  }

  function getStateColor(state) {
    switch (state) {
      case 'done':
        return Colors.ProgressDone;
      case 'started':
        return "indianred";
      case 'joined':
        return 'darkslateblue';
      default:
        return Colors.ProgressNotStarted;
    }
  }

  function getCraftIntent(state) {
    switch (state) {
      case 'done':
        return 'success';
      case 'started':
        return 'warning';
      case 'joined':
        return 'primary';
      default:
        return 'none';
    }
  }

  return error ? <span>Error {error.status}</span> :
    isLoading ? (<Spinner />) :
      <div style={styles.list}>
        {items.map((project, i) => (show(project) &&
          <Card key={i} style={styles.card} interactive={true}
            onClick={() => navigate(referrer === 'projects' ? `/project/${project.pk}/${project.id}` : `/@${project.pk}/${project.id}`)}>
            <center>
              {project.picture ? <img style={styles.img} src={project.picture} alt={project.name} /> :
                project.pictures && project.pictures.length > 0 ? <img style={styles.img} src={project.pictures[0]} alt={project.name} /> :
                  <div style={styles.noimg}><Icon icon={getCraftIcon(project.craft)} size={60} /></div>}
              <div style={styles.title}>{project.name}</div>
              <Tag minimal round intent={getCraftIntent(project.state)}>{project.craft}</Tag>
              <div>
                <Icon icon={project.summary ? 'eye-on' : 'eye-off'} color={project.summary ? 'green' : 'lightgrey'} />
                <span style={{ ...styles.state, color: getStateColor(project.state) }}> {project.state}</span>
              </div>
            </center>
          </Card>
        ))}
      </div>
}

const styles = {
  list: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  card: {
    borderRadius: 5,
    width: 124,
    height: 200,
    margin: 5,
    padding: 2
  },
  state: {
    fontSize: 8,
    fontWeight: 'bold',
  },
  noimg: {
    paddingTop: 16,
    backgroundColor: 'white',
    color: 'lightgrey',
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    height: 120,
    width: 120,
    borderRadius: 5
  },
  img: {
    height: 120,
    width: 120,
    borderRadius: 5
  },
  title: {
    minHeight: 24,
    fontSize: 'small',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    textAlign: 'center',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  }
}
