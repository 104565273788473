import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, HTMLSelect } from '@blueprintjs/core';
import { selectCurrentLabel, setCurrentLabel } from '../../store/itemSlice';
import { store } from '../../store/store';
import { labels } from '../../utils';
import NodeList from './NodeList';
import NodeDetails from './NodeDetails';
import NodeCreate from './NodeCreate';

export default function Nodes() {
  let params = useParams();
  const navigate = useNavigate();
  const currentLabel = useSelector(selectCurrentLabel);
  document.title = "Nodes";

  function handleLabelChange(event) {
    store.dispatch(setCurrentLabel(event.target.value));
  }

  return (
    <div>
      <label>Label:&nbsp;
        <HTMLSelect onChange={handleLabelChange} value={currentLabel} >
          {labels.map((label, i) => (
            <option key={i} value={label}>{label}</option>
          ))}
        </HTMLSelect>
      </label>
      <Button style={styles.button} onClick={() => navigate('/node/create')}>Create</Button>
      <div style={styles.main}>
        <div className="sidenav">
          <NodeList nodeId={params.id} nodePk={params.pk} />
        </div>
        <div className="maincontent" style={{ maxWidth: 600, padding: 20 }}>
          { params.id && params.id !== undefined && params.id === "create" ? <NodeCreate /> :
            params.id && params.pk && <NodeDetails nodePk={params.pk} nodeId={params.id} /> }
        </div>
      </div>
    </div>
  );
}

const styles = {
  main: {
    display: "flex",
    flexDirection: "row"
  },
  button: {
    margin: 10,
    verticalAlign: 'center'
  }
}
