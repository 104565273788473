import { useNavigate } from "react-router-dom";
import { Card, Icon, Spinner } from "@blueprintjs/core";
import { useGetItemsQuery } from '../../store/docApi';
import { getCraftIcon } from "../../utils";
import Craft from "../Widgets/Craft";
import Error from "../Widgets/Error";

export default function PatternList() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetItemsQuery({ label: 'pattern'})

  let items = [];
  if (data) {
    items = [...data]
    items.sort((p1, p2) => { return p2._ts - p1._ts });
  }

  if (error) {
    console.log(error)
  }
  return error ? <Error /> :
    isLoading ? (<Spinner />) :
      <div style={styles.list}>
        {items.map((pattern, i) => {
          let pic = pattern.picture ? pattern.picture : pattern.pictures && pattern.pictures.length > 0 ? pattern.pictures[0] : null;
          return (
          <Card key={i} style={styles.card} interactive
            onClick={()=>navigate(`/pattern/${pattern.pk}/${pattern.id}`)}>
            {pic ? <img style={styles.img} src={pic} alt={pattern.name} /> :
            <div style={styles.noimg}><Icon icon={getCraftIcon(pattern.craft)} size={90} /></div>}
            <div style={styles.name}>
                <Craft craft={pattern.craft} />
                {pattern.name}
            </div>
            {pattern.author && <span style={styles.link}>@{pattern.author}</span>}
          </Card>
          )}
        )}
      </div>
}

const styles = {
  list: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  card: {
    borderRadius: 5,
    width: 124,
    height: 190,
    margin: 5,
    padding: 2
  },
  img: {
    height: 120,
    width: 120,
    objectFit: 'cover',
    borderRadius: 5
  },
  noimg: {
    padding: 16,
    backgroundColor: 'white',
    color: 'lightgrey',
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    height: 120,
    width: 120,
    borderRadius: 5
  },
  link: {
    fontSize: 'small',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    textAlign: 'center',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    paddingTop: 5
  },
  name: {
    fontSize: 'small',
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    textAlign: 'center',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  }
}
