import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = "https://stitchso.search.windows.net/indexes/content/docs?api-version=2021-04-30-Preview&search=";
const queryKey = "jP5R0vbtEw1Es5lwXahZ6Oj5C63vAutRiYapD6tOReAzSeCD12qQ";

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set('api-key', queryKey);
      return headers
    },
  }),
  endpoints: (builder) => ({
    search: builder.query({
      query: (arg) => {
        const { term } = arg;
        return {
          url: `${term}`
        }
      }
    })
  })
})

export const {
  useSearchQuery
} = searchApi
