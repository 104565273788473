import { useState } from "react";
import { Button } from "@blueprintjs/core";
import { useGetInstagramQuery } from "../../store/docApi";

const graphurl = "https://graph.facebook.com/v16.0";

export default function Instagram({ imageUrl, caption }) {
    const { data: instainfo, isLoading } = useGetInstagramQuery();
    const [message, setMessage] = useState("");
    const [message2, setMessage2] = useState("");
    const [containerId, setContainerId] = useState("");
        
    async function instacheck()
    {
        let url = `${graphurl}/me/accounts?access_token=${instainfo.token}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.json();
    }

    function check() {
        instacheck().then((data) => {
            console.log(data);
            setMessage(JSON.stringify(data));
        });
    }

    async function instapost()
    {
        const data = {
            access_token: instainfo.token,
            image_url: imageUrl,
            caption: caption
        };
    
        let url = `${graphurl}/${instainfo.accountId}/media`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        })
        return response.json();
    }

    async function instapublish(creation_id)
    {
        const data = {
            access_token: instainfo.token,
            creation_id:  creation_id
        };
    
        let url = `${graphurl}/${instainfo.accountId}/media_publish`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        })
        return response.json();
    }
/*
    function publish() {
        instapublish().then((data) => {
            console.log(data);
            setMessage2(JSON.stringify(data));        
        })
    }
*/
    function post() {
        instapost().then((data) => {
            console.log(data);
            setMessage(JSON.stringify(data));
            if (data.id) {
                setContainerId(data.id);
                instapublish(data.id).then((data) => {
                    console.log(data);
                    setMessage2(JSON.stringify(data));        
                })
            }
        });
    }

   return ( isLoading ? <div>Initializing...</div> :
    <div>
        <div><b>Instagram post</b></div>
        <div><img src={imageUrl} alt="post" width="40" height="40" /></div>
        <div><pre>{caption}</pre></div>
        <Button onClick={()=>check()}>Check</Button>&nbsp;
        <Button onClick={()=>post()}>Post</Button>
        <div>{containerId}</div>
        <div>{instainfo.accountId}</div>
        <div><pre>{message}</pre></div>
        <div><pre>{message2}</pre></div>
    </div>
   )
}